@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://use.typekit.net/loj6qur.css");

body {
  font-family: "bebas-neue-pro", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
